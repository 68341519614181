import {callAPI, createActions} from '../../commons/actions/data-action-helper'
import {getFullLocale} from '../../commons/selectors/environment'
import {getRealEventsCount, isLayoutPaginated} from '../selectors/events'
import {GetState} from '../types/state'

export const LOAD_EVENTS = createActions('LOAD_EVENTS')
export const LOAD_ALL_EVENTS = createActions('LOAD_ALL_EVENTS')

export const reloadEvents = () => async (dispatch, getState: GetState) => {
  if (isLayoutPaginated(getState())) {
    return dispatch(loadEventsPage(0))
  } else {
    return dispatch(loadAllEvents())
  }
}

export const loadAllEvents = () => async (dispatch, getState: GetState, {compId}) => {
  const state = getState()
  const {membersEnabled, paidPlansEnabled} = state
  return dispatch(callAPI(LOAD_ALL_EVENTS, compId, membersEnabled, paidPlansEnabled, getFullLocale(state)))
}

export const loadEventsPage = (offset?: number) => async (dispatch, getState: GetState) => {
  const state = getState()
  const {membersEnabled, paidPlansEnabled} = state
  offset = offset ?? getRealEventsCount(state)
  const filter = state.component.byStatus?.filter
  return dispatch(callAPI(LOAD_EVENTS, offset, filter, membersEnabled, paidPlansEnabled, getFullLocale(state)))
}
